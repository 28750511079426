import React from "react"
import { navigate } from "gatsby"

const PrivateRoute = ({ component: Component, location, loggedIn, ...rest }) => {
  if (!loggedIn && location.pathname !== `/events/login`) {
    navigate("/events/login")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
