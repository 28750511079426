import { Card, CardContent } from "@material-ui/core"
import React, { useState } from "react"
import styled from "styled-components"

export const EventPage: React.FC = ({ content }) => {
  let message = "not logged in"

  const [active, setActive] = useState(false)
  // if(!isLoggedIn()){
  //   setActive(true)

  // }

  return (
    <>
      {content}
      {/* <h1>    {message}</h1> */}
    </>
  )
}

const StyledCard = styled(Card)`
  max-width: 520px;
`

const StyledCardContent = styled(CardContent)`
  padding: 50px;
`
