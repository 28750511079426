import { Card, CardContent } from "@material-ui/core"
import React, { useState } from "react"
import styled from "styled-components"
import { ErrorMessage, validateEmail } from "../../../pages/register"
import { makeApiCall } from "../../../services/apiCall"
import { ApiUrl } from "../../../util/apiUrl"
import { Button, SizeType } from "../../Buttons/Buttons"
import { Lightbox } from "../../Lightbox/Lightbox"
import { GFWSpinner } from "../../spinner/spinner"

interface EditEmailInterface {
  active: boolean
  setActive: any
}

export const EditEmail: React.FC<EditEmailInterface> = ({ active, setActive }) => {
  const [checkEmail, setCheckEmail] = useState({ loading: false, isFree: false })
  const [submitting, setSubmitting] = useState(false)
  function isEmailTaken(email) {
    setCheckEmail({ loading: true, isFree: false })
    return makeApiCall("/check-email-exists", { email }).then(res => {
      setCheckEmail({ loading: false, isFree: res.success })
    })
  }
  const getValidationClass = name => {
    if (errorState[name] === "pass") {
      return "validation-success"
    } else if (errorState[name] === "") {
      return ""
    } else {
      return "validation-error"
    }
  }
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const [state, setState] = useState({
    emailAddress: "",
  })

  const [errorState, setErrorState] = useState({
    emailAddress: "",
  })

  const inlineValidation = e => {
    const { value, name } = e.target
    let errorMessage = ""

    switch (name) {
      case "emailAddress":
        if (!validateEmail(value)) {
          errorMessage = "Please enter a valid email address"
          setErrorState({ ...errorState, [name]: errorMessage })
          break
        }
        if (!checkEmail.isFree) {
          errorMessage = "This email is taken. Please choose another."
          setErrorState({ ...errorState, [name]: errorMessage })
          break
        } else {
          setErrorState({ ...errorState, [name]: "pass" })
        }
        break

      default:
        break
    }
  }

  const handleSubmit = e => {
    setSubmitting(true)
    setErrorState({
      emailAddress: "",
    })
    e.preventDefault()
    //Final validation
    //Check the first infomation
    //Check the title
    if (state.emailAddress === "") {
      setErrorState({
        ...errorState,
        emailAddress: "Please enter your email address.",
      })
      return
    }
    if (!validateEmail(state.emailAddress)) {
      setErrorState({
        ...errorState,
        emailAddress: "Please enter a valid email address.",
      })
      return
    }
    if (!checkEmail.isFree) {
      setErrorState({ ...errorState, emailAddress: "This email is taken. Please choose another." })
      return
    }
    const data = { ...state }

    fetch(`${ApiUrl}/edit-email-address`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "Api-Key 18475794-de56-4ff0-a685-3d591fb81661",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => {
        return response.json()
      })
      .then(data => {})
      .catch(e => {
        setSubmitting(false)
      })
  }

  return (
    <Lightbox
      //  active={!isLoggedIn()}
      active={active}
      setActive={setActive}
    >
      <StyledCard>
        <StyledCardContent>
          <CardTitle>Change Personal Details</CardTitle>
          <HorizontalRule />
          <label id={"email"}>
            New email
            {checkEmail.loading ? <GFWSpinner size={10} style={{ marginLeft: 10 }} thickness={5} disableShrink /> : ""}
          </label>

          <input
            type={"email"}
            name={"emailAddress"}
            value={state.emailAddress}
            className={getValidationClass("emailAddress")}
            onChange={e => handleChange(e)}
            onBlur={e => isEmailTaken(e.target.value)}
          ></input>
          <ErrorMessage>
            {errorState.emailAddress !== "pass" && !checkEmail.loading ? errorState.emailAddress : " "}
          </ErrorMessage>

          <label id={"email"}>Re-type email</label>

          <input
            type={"email"}
            name={"emailAddressConfirm"}
            value={state.emailAddress}
            className={getValidationClass("emailAddressConfirm")}
            onChange={e => handleChange(e)}
            onBlur={e => isEmailTaken(e.target.value)}
          ></input>
          <ErrorMessage>
            {errorState.emailAddress !== "pass" && !checkEmail.loading ? errorState.emailAddress : " "}
          </ErrorMessage>

          <Button size={SizeType.large} to={"/register"} customwidth={"300px"} style={{ marginTop: 20 }}>
            Save Details
          </Button>
        </StyledCardContent>
      </StyledCard>
    </Lightbox>
  )
}

const CardTitle = styled.h6`
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 0px;
`

const StyledCard = styled(Card)`
  max-width: 520px;
`

const StyledCardContent = styled(CardContent)`
  padding: 45px;
`

const HorizontalRule = styled.hr`
  margin-top: 8px;
  margin-bottom: 10px;
`
