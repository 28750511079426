import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../services/auth"
import styled from "styled-components"
import { validateEmail } from "../../util/validateEmail"
import { ErrorMessage } from "../Form/ErrorMessageText"
import { Container } from "@material-ui/core"
import Breadcrumb from "../Breadcrumb"
import { GFWSpinner } from "../spinner/spinner"
export const Login = ({ SuccessfulLogin }) => {
  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Sign in",
      url: "/forgotten-password",
    },
  ]
  const [submitting, setSubmitting] = useState(false)
  const [failed, setFailed] = useState(false)
  const [successful, setSuccessful] = useState(false)

  const [state, setState] = useState({
    username: ``,
    password: ``,
  })
  const [errorState, setErrorState] = useState({
    username: ``,
    password: ``,
  })
  const handleUpdate = event => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSubmitting(true)
    handleLogin(state).then(r => {
      if (r === false || r === undefined) {
        setFailed(true)
        setSubmitting(false)
        return
      }

      if (isLoggedIn()) {
        setFailed(false)
        setSuccessful(true)
        setSubmitting(false)
        SuccessfulLogin()
        setTimeout(() => {
          navigate(`/events/account`)
        }, 1000)
      }
    })
  }

  function handleEmailBlur() {
    if (!validateEmail(state.username)) {
      setErrorState({
        ...errorState,
        username: "Please enter a valid email address.",
      })

      return
    } else {
      setErrorState({
        ...errorState,
        username: "",
      })
    }
  }
  return (
    <>
      <div style={{ backgroundColor: "#FAFAFA", width: "100%", border: "1px solid blue;" }}>
        <Container maxWidth={"lg"}>
          <Breadcrumb data={breadcrumbData} />
        </Container>
      </div>
      <Container>
        <DivContainer>
          <Header>
            <h2>WELCOME TO GRADUATE FASHION WEEK</h2>
            <SubHeading>Sign in to your account</SubHeading>
          </Header>
          <Form
            method="post"
            onSubmit={event => {
              handleSubmit(event)
            }}
          >
            <label>
              Email
              <input
                type="text"
                name="username"
                onChange={handleUpdate}
                onBlur={handleEmailBlur}
                value={state.username}
              />
            </label>
            <ErrorMessage>{errorState.username}</ErrorMessage>
            <label style={{ position: "relative" }}>
              Password
              <ForgottenPassword to={"/forgotten-password"}> Forgotten your password? </ForgottenPassword>
              <input type="password" name="password" value={state.password} onChange={handleUpdate} />
            </label>
            <ErrorMessage>{failed ? "Incorrect email or password. Please try again." : ""}</ErrorMessage>
            <SubmitButtonContainer>
              <input type="submit" value="SIGN IN" />
              {submitting ? <GFWSpinner size={10} style={{ position: "absolute", bottom: 20 }} thickness={5} disableShrink /> : null}
              {successful ? <span style={{color:"green", position: "absolute", bottom: 20, textSize:10 }}>Login successful, redirecting...</span> : null}
            </SubmitButtonContainer>
          
            <LinkContainer>
              {/* <Link to="/register">Not registered? Register to Graduate Fashion Week</Link> */}
            </LinkContainer>
          </Form>
        </DivContainer>
      </Container>
    </>
  )
}

const ForgottenPassword = styled(Link)`
  text-align: right;
  /* width:100%; */
  font-size: 18px;
  position: absolute;
  right: 0;
`

const DivContainer = styled.div`
  /* border: 1px solid red; */
  margin: 120px auto;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`

const SubHeading = styled.h5`
  text-transform: none;
`
const Header = styled.div`
  margin: 0px auto 54px;
  text-align: center;
`
const Form = styled.form`
  width: 100%;
  max-width: 660px;
`
const SubmitButtonContainer = styled.div`
position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid red; */
`
const LinkContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-weight: 500;
`
