import { Container } from "@material-ui/core"
import { graphql, Link, navigate, StaticQuery } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { getUser, logout } from "../../services/auth"
import { ChangePassword } from "./accountModals/ChangePassword"
import { EditDetails } from "./accountModals/EditDetails"
import { EditEmail } from "./accountModals/EditEmail"

export const Account: React.FC = () => {
  const [editDetailsOpen, setEditDetailsOpen] = useState(false)
  const [editEmailOpen, setEditEmailOpen] = useState(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)

  return (
    <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            amplifyBranch
          }
        }
      }
    `}
    //
    render={data => {return(
    <>
      <EditDetails active={editDetailsOpen} setActive={setEditDetailsOpen} />
      <ChangePassword active={changePasswordOpen} setActive={setChangePasswordOpen} />
      <EditEmail active={editEmailOpen} setActive={setEditEmailOpen} />

      <Container style={{ border: "1px solid blue;" }}>
        <Header>Account Information</Header>

        <AccountWrapper>
          <LeftColumn>
            <LeftHeading>Account Details</LeftHeading>
            <Link
              to="/"
              onClick={event => {
                event.preventDefault()
                logout(() => navigate(`/signed-out`))
              }}
            >
              Sign Out
            </Link>
          </LeftColumn>
          <RightColumn>
            <SectionHeading> ABOUT YOU </SectionHeading>
            <InfoContainer>
              <InfoRow>
                <Title>Name:</Title>
                <Info>
                  <Name>
                    {getUser().title} {getUser().firstName} {getUser().surname}
                  </Name>
                  {   data.site.siteMetadata.amplifyBranch !== "master" ?       
                  <EditInfo
                    onClick={() => {
                      setEditDetailsOpen(true)
                    }}
                  >
                    Edit details
                  </EditInfo> : <></>}
            
                </Info>
              </InfoRow>
            </InfoContainer>

            <SectionHeading> SIGN IN DETAILS </SectionHeading>
            <InfoContainer>
              <InfoRow>
                <Title>Email:</Title>
                <Info>
                  <Name>{getUser().emailAddress}</Name>

                  {   data.site.siteMetadata.amplifyBranch !== "master" ?       
                    <EditInfo
                    onClick={() => {
                      setEditEmailOpen(true)
                    }}
                  >
                    Edit email
                  </EditInfo> : <></>}

              
                </Info>
              </InfoRow>
              <InfoRow>
                <Title>Password:</Title>
                <Info>
                  <Password>********</Password>
                  {   data.site.siteMetadata.amplifyBranch !== "master" ?       
                            <EditInfo
                            onClick={() => {
                              setChangePasswordOpen(true)
                            }}
                          >
                            Change Password
                          </EditInfo> : <></>}
      
                </Info>
              </InfoRow>
            </InfoContainer>
          </RightColumn>
        </AccountWrapper>
      </Container>
    </>)}}/>
  )
}

const EditInfo = styled.div`
  color: #0db0e7;
  font-size: 22px;
  line-height: 32px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 24px;
  }
`

const Header = styled.h2`
  margin: 69px 0 80px;
`
const Password = styled.span`
  padding-top: 7px;
`

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto 177px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    margin: 0 auto 50px;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-left: 100px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding-left: 0px;
    padding-top: 40px;
  }
`
const LeftHeading = styled.p`
  padding: 24px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 3px solid #0db0e7;
  color: #0db0e7;
  font-size: 18px;
  font-weight: 600;
`

const SectionHeading = styled.p`
  overflow: hidden;
  position: relative;
  color: #4a4a4a;
  font-size: 16px;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background: ${props => props.theme.lines.grey};
    position: absolute;
    top: 50%;
    margin-left: 1rem;
  }
`

const InfoContainer = styled.div`
  padding-bottom: 40px;
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`
const Title = styled.h5`
  width: 150px;
  color: ${props => props.theme.colors.grey1};
  text-transform: none;
`
const Info = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  text-transform: none;
`
const Name = styled.h5`
  text-transform: none;
`
