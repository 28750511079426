import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import { withStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { DateTime } from "luxon"
import { ButtonSecondary, SizeType } from "./Buttons/Buttons"
import { v4 as uuidv4 } from "uuid"
interface ISchedule {
  data
}

const Accordion = withStyles({
  root: {
    marginBottom: "3px",

    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",

    minHeight: 50,
    "&$expanded": {
      minHeight: 50,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "#0DB0E7",
    color: "white",
  },
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const Schedule: React.FC<ISchedule> = ({ data }) => {
  const initShowing = 5
  const [selected, setSelected] = useState(data[0])
  const [showFull, setShowFull] = useState(false)
  const [button, setButton] = useState(<></>)

  const [expanded, setExpanded] = React.useState("-1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  useEffect(() => {
    const buttonMes = !showFull ? "SHOW FULL SCHEDULE" : "COLLAPSE SCHEDULE"
    const b = (
      <ButtonSecondary customwidth={"300px"} size={SizeType.large}>
        {buttonMes}
      </ButtonSecondary>
    )
    if (selected.data.length > 4) {
      setButton(b)
    } else {
      setButton(null)
    }
  }, [selected, showFull])

  const dateRadioButtons = data.map((v, i) => {
    const date = DateTime.fromISO(v.date)

    return (
      <>
        <input
          type={"radio"}
          id={"date-rad-" + i}
          name={"date"}
          value={selected}
          checked={v.date === selected.date}
          onChange={e => {
            setSelected(v)
          }}
        />
        <StyledRadioLabel htmlFor={"date-rad-" + i}>
          <DayLabel>{date.day}</DayLabel>

          <MonthLabel>{date.toLocaleString({ month: "long", year: "numeric" })}</MonthLabel>
        </StyledRadioLabel>
      </>
    )
  })

  const dateAccordions = data.map((v, i) => {
    const date = DateTime.fromISO(v.date)

    return (
      <Accordion expanded={expanded === i} onChange={handleChange(i)} key={uuidv4()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={i}>
          <MobileDayLabel>{date.day}</MobileDayLabel>
          <MobileMonthLabel>{date.toLocaleString({ month: "long", year: "numeric" })}</MobileMonthLabel>
        </AccordionSummary>
        <AccordionDetails>
          {v.data.map((v, i) => {
            const startTime = DateTime.fromISO(v["start-time"])
            const endTime = DateTime.fromISO(v["end-time"])
            if (!showFull && i > 3) {
              return
            }
            return (
              <div key={"schedule-item-" + i}>
                <StartTime>
                  {startTime.toLocaleString({ hour: "numeric", minute: "2-digit" })} -{" "}
                  {endTime.toLocaleString({ hour: "numeric", minute: "2-digit" })}
                </StartTime>
                <Description>{v.description}</Description>
              </div>
            )
          })}
          <div
            onClick={() => {
              setShowFull(!showFull)
            }}
          >
            {button}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  })

  return (
    <CrossHeadingContainer>
      <HeadingContainer>
        <Cross />
        <CrossHeadingTitlesContainer>
          {/* <CrossHeadingSubtitle>{subtitle}</CrossHeadingSubtitle> */}
          <CrossHeadingTitle></CrossHeadingTitle>
        </CrossHeadingTitlesContainer>
      </HeadingContainer>
      <ScheduleContainer>
        <DesktopSchedule>
          {/* <CrossHeadingText> */}
          <RadioButtonsContainer>{dateRadioButtons.map(v => v)}</RadioButtonsContainer>

          <div>
            {selected.data.map((v, i) => {
              const startTime = DateTime.fromISO(v["start-time"])
              const endTime = DateTime.fromISO(v["end-time"])

              if (!showFull && i > 4) {
                return
              }
              return (
                <div key={"schedule-item-" + i}>
                  <StartTime>
                    {startTime.toLocaleString({ hour: "numeric", minute: "2-digit" })} -{" "}
                    {endTime.toLocaleString({ hour: "numeric", minute: "2-digit" })}
                  </StartTime>
                  <Description>{v.description}</Description>
                </div>
              )
            })}
            <div
              onClick={() => {
                setShowFull(!showFull)
              }}
            >
              {button}
            </div>
          </div>
        </DesktopSchedule>
        <MobileSchedule>{dateAccordions.map(v => v)}</MobileSchedule>
        {/* </CrossHeadingText> */}
      </ScheduleContainer>
    </CrossHeadingContainer>
  )
}

export default Schedule

const CrossHeadingContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  /* align-items: center; */

  position: relative;
  left: -80px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0px;
  }
`

const CrossHeadingTitlesContainer = styled.div<{ titleWidth: string }>`
  /* border: 1px solid red; */
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: -80px;
  width: 100%;
`

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const DesktopSchedule = styled.div`
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`
const MobileSchedule = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`
const RadioButtonsContainer = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
`
const Description = styled.p`
  max-width: 749px;
  color: #4a4a4a;
  font-family: "Barlow Condensed";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    line-height: 24px;
    width: 100%;
    font-size: 14px;
  }
`

const CrossHeadingTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  min-width: 320px;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 33px;
  }
`

const StartTime = styled.span`
  height: 30px;
  width: 95px;
  color: #0db0e7;
  font-family: "Barlow Condensed";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

const Cross = styled.div`
  max-width: 160px;
  max-height: 160px;
  width: 160px;
  height: 160px;
  @media (max-width: 768px) {
    width: 124px;
    height: 124px;
  }
`

const DayLabel = styled.span`
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  display: block;
`
const MonthLabel = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`

const StyledRadioLabel = styled.label`
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  /* max-height: 90px !important;
max-width: 90px !important; */
  padding: 15px !important;
  min-height: 90px !important;
  width: auto !important;
  //min-width: 70px !important;
`
const MobileDayLabel = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`
const MobileMonthLabel = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin-left: 5px;
  text-transform: uppercase;
`
