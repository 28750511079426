import { Card, CardContent } from "@material-ui/core"
import React, { useState } from "react"
import styled from "styled-components"
import { Lightbox } from "../../Lightbox/Lightbox"
import { Button, SizeType } from "../../Buttons/Buttons"
import { ApiUrl } from "../../../util/apiUrl"
import { ErrorMessage } from "../../../pages/register"
interface EditDetailsInterface {
  active: boolean
  setActive: any
}

export const EditDetails: React.FC<EditDetailsInterface> = ({ active, setActive }) => {
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = e => {
    setSubmitting(true)
    setErrorState({
      title: "",
      firstName: "",
      surname: "",
    })
    e.preventDefault()

    //Final validation
    //Check the first infomation

    //Check the title
    if (state.title === "") {
      setErrorState({
        ...errorState,
        title: "Please select your title option.",
      })
      return
    }
    if (state.firstName === "") {
      setErrorState({
        ...errorState,
        firstName: "Please enter your first name.",
      })

      return
    }
    if (state.surname === "") {
      setErrorState({ ...errorState, surname: "Please enter your surname." })

      return
    }
    //send the result

    const data = {
      ...state,
    }

    fetch(`${ApiUrl}/edit-details`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "Api-Key 18475794-de56-4ff0-a685-3d591fb81661",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => {
        return response.json()
      })
      .then(data => {console.log(data)})
      .catch(e => {
        setSubmitting(false)
      })
  }

  const getValidationClass = name => {
    if (errorState[name] === "pass") {
      return "validation-success"
    } else if (errorState[name] === "") {
      return ""
    } else {
      return "validation-error"
    }
  }
  const inlineValidation = e => {
    const { value, name } = e.target
    let errorMessage = ""

    switch (name) {
      case "firstName":
        if (value === "") {
          errorMessage = "Please enter your first name."
          setErrorState({ ...errorState, [name]: errorMessage })
        } else {
          setErrorState({ ...errorState, [name]: "pass" })
        }
        break
      case "surname":
        if (value === "") {
          errorMessage = "Please enter your surname."
          setErrorState({ ...errorState, [name]: errorMessage })
        } else {
          setErrorState({ ...errorState, [name]: "pass" })
        }
        break
      default:
        break
    }
  }

  const titleArray = ["Mr", "Mrs", "Miss", "Ms"]

  const [state, setState] = useState({
    title: "",
    firstName: "",
    surname: "",
  })
  const [errorState, setErrorState] = useState({
    title: "",
    firstName: "",
    surname: "",
  })

  const titleRadioButtons = titleArray.map((title, i) => (
    <>
      <input
        type={"radio"}
        id={"title-rad-" + i}
        name={"title"}
        value={title}
        onChange={e => {
          setState({ ...state, title: e.target.value })
        }}
      />
      <label htmlFor={"title-rad-" + i}>{title}</label>
    </>
  ))
  return (
    <Lightbox
      //  active={!isLoggedIn()}
      active={active}
      setActive={setActive}
    >
      <StyledCard>
        <StyledCardContent>
          <CardTitle>Change Personal Details</CardTitle>
          <HorizontalRule />
          <form onSubmit={handleSubmit}>
            <label id={"title"}>Title</label>

            {titleRadioButtons.map(v => v)}
            <ErrorMessage>{errorState.title}</ErrorMessage>

            <div>
              <label id={"firstName"}>First Name</label>

              <input
                type={"text"}
                name={"firstName"}
                className={getValidationClass("firstName")}
                value={state.firstName}
                onChange={e => handleChange(e)}
                onBlur={e => inlineValidation(e)}
              ></input>
              <ErrorMessage>{errorState.firstName !== "pass" ? errorState.firstName : " "}</ErrorMessage>
            </div>

            <div>
              <label id={"surname"}>Surname</label>

              <input
                type={"text"}
                name={"surname"}
                className={getValidationClass("surname")}
                value={state.surname}
                onChange={e => handleChange(e)}
                onBlur={e => inlineValidation(e)}
              ></input>
              <ErrorMessage>{errorState.surname !== "pass" ? errorState.surname : " "}</ErrorMessage>
            </div>
          </form>

          <Button size={SizeType.large} to={"/register"} customwidth={"300px"} style={{ marginTop: 20 }}>
            Update Details
          </Button>
        </StyledCardContent>
      </StyledCard>
    </Lightbox>
  )
}

const CardTitle = styled.h6`
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 0px;
`

const StyledCard = styled(Card)`
  max-width: 520px;
`

const StyledCardContent = styled(CardContent)`
  padding: 45px;
`

const HorizontalRule = styled.hr`
  margin-top: 8px;
  margin-bottom: 10px;
`
