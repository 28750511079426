import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { Layout } from "../components/layout"

import { Router } from "@reach/router"
import PageHeading from "../components/PageHeading/PageHeading"

import { graphql } from "gatsby"
import { Account } from "../components/authRoutes/account"
import { Login } from "../components/authRoutes/login"
import { Card, CardContent, Container } from "@material-ui/core"
import PrivateRoute from "../components/authRoutes/PrivateRoutes"

import { EventPage } from "../components/authRoutes/events"
import { parse } from "@wordpress/block-serialization-default-parser"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import Schedule from "../components/Schedule"
import { EventsCarosel } from "../components/EventsCarosel/EventsCarosel"
import { Lightbox } from "../components/Lightbox/Lightbox"
import { testToken } from "../services/apiCall"
import { Button, ButtonSecondary, SizeType } from "../components/Buttons/Buttons"
import { isLoggedIn } from "../services/auth"
const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Event",
    url: "/events",
  },
]

const Event: React.FC = ({ data, location }) => {

  const events = data.allWpEvent.edges.map(v => v.node)

  let groupedEvents = events.reduce((groups, event) => {
     const splitString = event.events.timeOfEvent.split(' ');
     const date = splitString[0];
     if(!groups[date]) groups[date] = [];
     groups[date].push(event);
     return groups;
  }, {});

  let eventsAsArray = Object.entries(groupedEvents)

  eventsAsArray.sort((a,b) => {
    const firstDate = a[0]
    const secondDate = b[0]
    function rearrangeDate(date:string){
      const items = date.split("/")
      return items[2]+items[1]+items[0]
    }
    return rearrangeDate(firstDate) - rearrangeDate(secondDate)
  })

  eventsAsArray.reverse()

  const eventsPage = parse(data.wpPage.lazy_data)
  const scheduleBlocks = eventsPage.filter(v => v.blockName === "lazyblock/event-schedule-unit")
  const formatted = scheduleBlocks.map(v => {
    return { date: v.attrs.date, data: JSON.parse(decodeURI(v.attrs["event-contents"])) }
  })
  // Other blocks
  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)
  const [active, setActive] = useState(false)
  const [video, setVideo] = useState({ title: "", url: "" })
  const [loggedIn, setIsLoggedIn] = useState(undefined)
  useEffect(() => {
    let mount = true
    if (mount) {
      testToken()
      setIsLoggedIn(isLoggedIn())
    }
    return () => {
      mount = false
    }
  }, [])

  const SuccessfulLogin = () => {
    setIsLoggedIn(isLoggedIn())
  }

  const eventsPageContent = (
    <>
      <Lightbox active={false} setActive={setActive}>
        <StyledCard>
          <StyledCardContent>
            <h3>Watch GFW live event</h3>
            <p>
              To get access to watching our live event lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
              eiusmod tempor event lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor.
            </p>
            <div style={{ margin: "auto" }}>
              <ButtonSecondary size={SizeType.large} to={"/events/login"} customwidth={"300px"}>
                Sign In
              </ButtonSecondary>
              <Button size={SizeType.large} to={"/register"} customwidth={"300px"} style={{ marginTop: 20 }}>
                Register Now
              </Button>
            </div>
          </StyledCardContent>
        </StyledCard>
      </Lightbox>
      <PageHeading
        breadcrumbData={breadcrumbData}
        //  content={content}
        pageTitle="GFW Event"
      />
      <Container>
        {blocks.map((v, i) => {
          if (i == 7) {
            return (
              <>
                <Schedule data={formatted} />
                {v}
              </>
            )
          } else {
            return v
          }
        })}
      </Container>
        {/* {console.log(Object.entries(sortedEvents))} */}
      {eventsAsArray.map((v,i)=>{
      //  console.log(v)
      //  console.log(v[1])
       return(
 <EventsCarosel events={v[1]} date={v[0]} setActive={setActive} setVideo={setVideo} key={"events-caruosel-" + v[0]}/>
      )})}
     
    </>
  )

  if (loggedIn === undefined) {
    return (
      <Layout title={"Graduate Fashion Week - Events"}>
        <div>Loading</div>
      </Layout>
    )
  } else {
    return (
      <Layout title={"Graduate Fashion Week - Events"}>
        <Router style={{ width: "100%" }} basepath="/events">
          <PrivateRoute component={Account} path="/account" location={location} loggedIn={loggedIn} />
          <Login path="/login" SuccessfulLogin={SuccessfulLogin} />
          <EventPage path="/" default content={eventsPageContent} />
        </Router>
      </Layout>
    )
  }
}

export default Event
const StyledCard = styled(Card)`
  max-width: 520px;
`

const StyledCardContent = styled(CardContent)`
  padding: 50px;
`

export const pageQuery = graphql`
  query events {
    wpPage(title: { eq: "EVENT & LIVESTREAM" }) {
      id
      title
      content
      lazy_data
    }
    allWpEvent {
      edges {
        node {
          id
          title
          events {
            vimeoUrl
            timeOfEvent
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
