import { Card, CardContent } from "@material-ui/core"
import React, { useState } from "react"
import styled from "styled-components"
import { ErrorMessage } from "../../../pages/register"
import { ApiUrl } from "../../../util/apiUrl"
import { Lightbox } from "../../Lightbox/Lightbox"
import { GFWSpinner } from "../../spinner/spinner"
import RoundTick from "../../../assets/forms/round-tick.svg"
import RoundCross from "../../../assets/forms/round-cross.svg"
interface ChangePasswordInterface {
  active: boolean
  setActive: any
}

export const ChangePassword: React.FC<ChangePasswordInterface> = ({ active, setActive }) => {
  const [submitting, setSubmitting] = useState(false)

  const getValidationClass = name => {
    if (errorState[name] === "pass") {
      return "validation-success"
    } else if (errorState[name] === "") {
      return ""
    } else {
      return "validation-error"
    }
  }
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  })
  const [passwordValiation, setPasswordValidation] = useState({
    length: false,
    UpperLower: false,
    number: false,
    matching: false,
  })
  const handlePasswordValidiation = e => {
    const { value, name } = e.target
    setState({ ...state, [e.target.name]: e.target.value })

    let validation = passwordValiation
    if (name === "password") {
      if (value.length >= 8) {
        validation = { ...validation, length: true }
      } else {
        validation = { ...validation, length: false }
      }
      if (value.search(/[a-z]/) >= 0 && value.search(/[A-Z]/) >= 0) {
        validation = { ...validation, UpperLower: true }
      } else {
        validation = { ...validation, UpperLower: false }
      }

      if (value.search(/[0-9]/) >= 0) {
        validation = { ...validation, number: true }
      } else {
        validation = { ...validation, number: false }
      }
      if (value === state.confirmPassword) {
        validation = { ...validation, matching: true }
      } else {
        validation = { ...validation, matching: false }
      }
    }
    if (name === "confirmPassword") {
      if (value === state.password) {
        validation = { ...validation, matching: true }
      } else {
        validation = { ...validation, matching: false }
      }
    }

    setPasswordValidation(validation)
  }

  const [errorState, setErrorState] = useState({
    password: "",
    confirmPassword: "",
  })

  const handleSubmit = e => {
    setSubmitting(true)
    setErrorState({
      password: "",
      confirmPassword: "",
    })
    e.preventDefault()
    if (
      !passwordValiation.UpperLower ||
      !passwordValiation.length ||
      !passwordValiation.matching ||
      !passwordValiation.number
    ) {
      setErrorState({
        ...errorState,
        password: "Please resolve the issues with your password.",
      })

      return
    }
    const data = { ...state }

    fetch(`${ApiUrl}/change-password`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "Api-Key 18475794-de56-4ff0-a685-3d591fb81661",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        return data
      })
      .catch(e => {
        setSubmitting(false)
      })
  }

  return (
    <Lightbox active={active} setActive={setActive}>
      <StyledCard>
        <StyledCardContent>
          <CardTitle>Change Password</CardTitle>
          <HorizontalRule />

          <>
            <label id={"password"}>Password</label>

            <input
              type={"password"}
              name={"password"}
              value={state.password}
              onChange={handlePasswordValidiation}
            ></input>
            <br />
            <br />
            <label>Your password must have</label>
            <Columns>
              <PasswordStrength>
                <p>{passwordValiation.length ? <RoundTickStyled /> : <RoundCrossStyled />}8 or more characters</p>
                <p>
                  {passwordValiation.number ? <RoundTickStyled /> : <RoundCrossStyled />}
                  At least one number
                </p>
              </PasswordStrength>
              <PasswordStrength>
                <p>
                  {passwordValiation.UpperLower ? <RoundTickStyled /> : <RoundCrossStyled />}
                  Upper and lower case letters
                </p>

                <p>
                  {passwordValiation.matching ? <RoundTickStyled /> : <RoundCrossStyled />}
                  Must match confirmation
                </p>
              </PasswordStrength>
              <div></div>
            </Columns>
            <br />

            <label>Confirm Password</label>

            <input
              type={"password"}
              name={"confirmPassword"}
              value={state.confirmPassword}
              onChange={handlePasswordValidiation}
            ></input>
            <ErrorMessage>{errorState.password !== "pass" ? errorState.password : " "}</ErrorMessage>
            <br />
            <div
              style={{
                // border: "1px solid red",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input type="submit" name={"submit"} value={"REGISTER"}></input>
              {submitting ? <GFWSpinner size={10} style={{ marginLeft: 10 }} thickness={5} disableShrink /> : null}
              {/* <p>{successMessage}</p> */}
            </div>
          </>
        </StyledCardContent>
      </StyledCard>
    </Lightbox>
  )
}

const CardTitle = styled.h6`
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 0px;
`

const StyledCard = styled(Card)`
  max-width: 520px;
`

const StyledCardContent = styled(CardContent)`
  padding: 45px;
`

const HorizontalRule = styled.hr`
  margin-top: 8px;
  margin-bottom: 10px;
`

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    + div {
      margin-left: 30px;
      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        margin-left: 0px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const RoundTickStyled = styled(RoundTick)`
  height: 15px;
  width: 15px;
  margin-right: 10px;
  top: 3px;
  position: relative;
  fill: ${props => props.theme.colors.turquoise};
  color: ${props => props.theme.colors.turquoise};
`
const RoundCrossStyled = styled(RoundCross)`
  height: 15px;
  width: 15px;
  margin-right: 10px;
  top: 3px;
  position: relative;
`
const PasswordStrength = styled.div`
  > p {
    margin-bottom: 16px;
    padding-bottom: 0px;
    line-height: 16px;
  }
`
